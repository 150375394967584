<template>
  <div>
    <custom-breadcrumb :customBreadcrumb="customBreadcrumb"></custom-breadcrumb>
    <div class="row">
      <div class="col-12">
        <v-alert color="cyan" border="left" elevation="2" colored-border>
          <v-form ref="form" v-model="isFormValid" class="w-100">
            <div class="row">
              <div class="col-6">
                <v-text-field
                  v-model="brand"
                  :counter="255"
                  :rules="brand_rules"
                  label="Marka"
                ></v-text-field>
              </div>
              <div class="col-6">
                <v-text-field
                  v-model="model"
                  :counter="255"
                  :rules="model_rules"
                  label="Model"
                ></v-text-field>
              </div>
              <div class="col-6">
                <v-text-field
                  v-model="mppt_channel_count"
                  :rules="mppt_channel_count_rules"
                  label="Kanal Sayısı"
                  type="integer"
                ></v-text-field>
              </div>
              <div class="col-6">
                <v-text-field
                  v-model="power"
                  :rules="power_rules"
                  label="Güç"
                  type="integer"
                  step="any"
                ></v-text-field>
              </div>
              <div class="col-12 text-center">
                <v-btn
                  :disabled="!isFormValid"
                  color="error"
                  class="mr-4 bg-success"
                  @click="save"
                  >Kaydet</v-btn
                >
              </div>
            </div>
          </v-form>
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import customBreadcrumb from "@/view/custom/breadcrumb";
import { STORE_INVERTER_TYPE } from "@/core/services/store/inverterTypes.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "inverter-types.create",
  components: {
    customBreadcrumb,
  },
  data() {
    return {
      //Breadcrumb
      customBreadcrumb: {
        title: "İnvertör Tipleri",
        type: "Ekle",
        pathName: null,
      },
      //Form Validation
      isFormValid: false,
      brand: "",
      brand_rules: [
        (v) => !!v || "Boş bırakılamaz",
        (v) => (v && v.length <= 255) || "Ad 255 karakterden az olmalıdır.",
      ],
      model: "",
      model_rules: [
        (v) => !!v || "Boş bırakılamaz",
        (v) => (v && v.length <= 255) || "Ad 255 karakterden az olmalıdır.",
      ],
      mppt_channel_count: "",
      mppt_channel_count_rules: [
        (v) => !!v || "Boş bırakılamaz",
        (v) => !isNaN(parseInt(v)) || "Sayı olmalıdır.",
      ],
      power: "",
      power_rules: [
        (v) => !!v || "Boş bırakılamaz",
        (v) => !isNaN(parseFloat(v)) || "Sayı olmalıdır.",
      ],
    };
  },
  methods: {
    save() {
      if (this.isFormValid) {
        let brand = this.brand;
        let model = this.model;
        let mppt_channel_count = this.mppt_channel_count;
        let power = this.power;
        this.$store
          .dispatch(STORE_INVERTER_TYPE, {
            brand,
            model,
            mppt_channel_count,
            power,
          })
          .then((res) => {
            this.$router.push({ name: "inverter-types" });
          });
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "İnvertör Tipleri",
        route: "/inverter-types",
        icon: "flaticon-dashboard",
      },
      { title: "Ekle" },
    ]);
  },
  computed: {
    ...mapGetters(["breadcrumbs", "pageTitle"]),
  },
};
</script>

<style scoped>
</style>
